<template>
    <div class="upload-img-list">
        <!-- <el-upload
            action="/api/qiniu/getImageUrl"
            list-type="picture-card"
            :file-list="imageUrlList"
            :before-upload="beforeAvatarUpload"
            :on-progress="progress"
            :on-preview="handlePictureCardPreview"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog> -->
        <!-- <el-upload
            :http-request="upLoadImg"
            action
            list-type="picture-card"
            :file-list="imageUrlList"
            :before-upload="beforeAvatarUpload"
            :on-remove="handleRemove"
            :on-preview="handlePictureCardPreview">
            <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog> -->
        <el-upload
            action="/api/qiniu/getImageUrl"
            list-type="picture-card"
            :file-list="imageUrlList"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePictureCardPreview1"
            :on-success="handleAvatarSuccess1"
            :on-remove="handleRemove1">
            <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
             <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>
<script>
import companyRequest from '../../api/company'
export default {
    props: {
        // 图片路径
        imageUrlList: {
            type: Array,
            default: () => {
                return []
            }
        },
        size: {
            type: Number,
            default: 5 // 默认支持上传5张
        }
    },
    data() {
        return {
            url: [],
            notice: '',
            dialogImageUrl: '',
            dialogVisible: false
        }
    },
    methods: {
        //上传前做图片以及大小验证
        beforeAvatarUpload(file) {
            // console.log('file', file)
            // console.log('imageUrlList', this.imageUrlList)
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 5
            if (this.imageUrlList.length >= this.size) {
                this.$message.error(`最多支持上传${this.size}张`)
                return false
            }
            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG 格式和PNG格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!')
            }
            return isJPG && isLt2M
        },
        //上传请求
        upLoadImg(file) {
            // console.log('file', file)
            let formData = new FormData()
            formData.append("file", file.file)
            this.notice = this.$notify({
            title: '上传图片',
            message: '图片正在上传中，请稍后...',
            type: 'warning',
            })
            companyRequest.postImg(formData).then((res) => {
                if (res.code === 200) {
                    this.notice.close()
                    this.url = res.data.message
                    console.log('this.url', this.url)
                    this.$emit('upData', this.url)
                }
            })
        },
        handleRemove1(file, fileList) {
            // console.log('file', file);
            // console.log('fileList', fileList);
            this.url = fileList
            this.$emit('removeData', this.url)
        },
        handlePictureCardPreview1(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleAvatarSuccess1(res, file, fileList) {
            // console.log('res', res)
            // console.log('file', file)
            // console.log('fileList', fileList)
            this.url = fileList
            this.$emit('upData', fileList)
        },
        // progress() {
        //     this.notice = this.$notify({
        //     title: '上传图片',
        //     message: '图片正在上传中，请稍后...',
        //     type: 'warning',
        //     })
        // },
        // 点击图片预览
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 文件上传成功 
        // handleAvatarSuccess(res, file, fileList) {
        //     // console.log('res', res)
        //     // console.log('file', file)
        //     // console.log('fileList', fileList)
        //     if (res.data.code === 200) {
        //         this.url = {url: res.data.message}
        //         console.log('this.url', this.url)
        //         this.$emit('upData', this.url)
        //         this.notice.close()
        //         this.$message.success('图片上传成功~') 
        //     }
        // },
        handleRemove(file, fileList) {
            // console.log('handleRemovefile', file)
            // console.log('handleRemovefileList', fileList)
            // this.url = fileList
            this.url = {url: file.url}
            this.$emit('removeData', this.url) 

        },
    },
    watch: {
        //像外暴露生成图片数组数据
        url(val) {
            this.$emit('upData', val)
        }
    }
}
</script>
<style lang="scss" scoped>
// .el-icon-plus{
//     font-size: 50px;
//     color: #999;
//     opacity: .7;
//     .el-upload__input{
//         margin-top: 15px;
//     }
// }
</style>