<template>
    <div class="upload-video flex">
        <!-- <video :src="videoUrl" height="147" controls="controls" v-if="videoUrl" autoplay>
            您的浏览器不支持 video 标签。
        </video>
        <el-upload
        class="video-uploader"
        action="/api/qiniu/getImageUrl"
        :show-file-list="false"
        v-else
        :on-success="handleVideoSuccess"
        :on-progress="handleVideoProgress"
        :before-upload="beforeVideoUpload">
        <i class="el-icon-plus"></i>
        </el-upload> -->
        <video
          v-if="videoUrl"
          :src="videoUrl"
          class="avatar video-avatar"
          height="148px"
          controls="controls"
          autoplay
        >您的浏览器不支持视频播放</video>
        <el-upload
        list-type="picture-card"
        action="/api/qiniu/getImageUrl"
        :on-progress="handleVideoProgress"
        :on-success="handleVideoSuccess"
        :on-error="handleVideoError"
        :before-upload="beforeVideoUpload"
        :show-file-list="false"
      >
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>
</template>
<script>
export default {
    props: {
        videoUrl: {
            type: String,
            default: ''
        },
        videoStatus: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        // url(val) {
        //     this.$emit('videoSuccess', val)
        // }
    },
    data() {
        return {
            url: '', //视频地址
            notice: ''
        }
    },
    methods: {
        handleVideoSuccess(res, file, fileList) {
            // console.log(1)
            // console.log('res', res)
            // console.log('file', file)
            // console.log('fileList', fileList)
            // this.url = fileList[0] && fileList[0].response.data.message
            this.url = res.code === 200 && res.data.message
            let zsarr=this.url.split(",")
            this.$emit('changVideoStatus', 'success')
            this.$emit('videoSuccess', zsarr)
            // this.notice.close()
        },
        handleVideoProgress() {
            // this.notice = this.$notify({
            // title: '上传视频',
            // message: '视频正在上传中，请稍后...',
            // type: 'warning',
            // })
            this.$emit('changVideoStatus', 'upload')
        },
        handleVideoError(err, file, fileList) {
            this.$emit('changVideoStatus', 'error')
        },
        beforeVideoUpload(file) {
            var fileSize = file.size / 1024 / 1024 < 50
            if (
                [
                'video/mp4',
                'video/ogg',
                'video/flv',
                'video/avi',
                'video/wmv',
                'video/rmvb',
                'video/mov'
                ].indexOf(file.type) == -1
            ) {
                this.$message.error('请上传正确的视频格式')
                return false;
            }
            if (!fileSize) {
                this.$message.error('视频大小不能超过50MB')
                return false;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '../../assets/css/global.scss';
    .el-upload-list--picture-card .el-upload-list__item,
    .el-upload--picture-card,
    .pic_img_box {
    width: 120px !important;
    height: 120px !important;
    line-height: 120px !important;
    }
    .el-upload-list--picture-card .el-upload-list__item-status-label i {
    position: absolute;
    left: 14px;
    top: 0px;
    }
    video{
        margin-right: 15px;
    }
    .pic_video {
        .close {
            position: absolute;
            top: 2px;
            right: -4px;
            font-size: 16px;
            cursor: pointer;
        }
    }
</style>