<template>
    <div class="company-info">
        <div class="title">
            <div class="sub-title">基本资料</div>
        </div>
        <el-card class="box-card">
            <el-row>
                <el-col :span="12">
                    <el-form ref="companyFormRef" :model="companyForm" :rules="companyFormRules" label-position="top" label-width="80px">
                        <el-form-item label="公司名称:" prop="companyName">
                            <el-input v-model="companyForm.companyName" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="从事行业:" prop="companyIndustryId">
                            <el-select v-model="companyForm.companyIndustryId" placeholder="请选择公司所属行业" disabled>
                                <el-option
                                v-for="item in industryList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="注册资本:" prop="register">
                            <el-input placeholder="请输入公司注册资金" v-model="companyForm.register">
                                <template slot="append">万</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="企业性质:" prop="companyNatureId">
                            <el-select v-model="companyForm.companyNatureId" placeholder="请选择公司所属性质">
                                <el-option
                                v-for="item in natureList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="企业规模:" prop="companyScale">
                            <el-select v-model="companyForm.companyScale" placeholder="请选择公司规模人数">
                                <el-option
                                v-for="item in companyScaleist"
                                :key="item.name"
                                :label="item.name"
                                :value="item.name">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="公司联系人:" prop="companyContactsName">
                            <el-input v-model="companyForm.companyContactsName"  placeholder="请填写公司联系人姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="公司联系人电话:" prop="companyContactsPhone">
                            <el-input v-model="companyForm.companyContactsPhone" placeholder="请填写公司联系人电话"></el-input>
                        </el-form-item>
                        <el-form-item label="公司固话:">
                            <el-input v-model="companyForm.companyFixedTelephone" placeholder="请填写公司固话"></el-input>
                        </el-form-item>
                        <el-form-item label="公司邮箱:">
                            <el-input v-model="companyForm.companyEmail" placeholder="请填写公司邮箱"></el-input>
                        </el-form-item>
                        <el-form-item label="工作区域: " required>
                            <!-- <el-input v-model="jobForm.address" style="width:500px" placeholder="请填写该职位所在工作地点" size="small"></el-input> -->
                            <div class="work-layer flex">
                                <el-select v-model="province" placeholder="请选择省份" @change="handleProvinceChange" size="small" style="width:200px;margin-right:10px">
                                    <el-option
                                    v-for="item in provinceList"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                                <el-select v-model="city" placeholder="请选择城市" @change="handlecityChange" size="small" style="width:200px;margin-right:10px">
                                    <el-option
                                    v-for="item in cityList"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                                <el-select v-model="area" v-if="areaList.length>0" placeholder="请选择区县" @change="handleAreaChange" size="small" style="width:200px;margin-right:10px">
                                    <el-option
                                    v-for="item in areaList"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                        <el-form-item label="详细地址:" prop="companyAddress">
                            <el-input v-model="companyForm.companyAddress" placeholder="请填写公司详细地址(如街道、小区、楼栋号等)"></el-input>
                        </el-form-item>
                        <el-form-item label="企业环境:">
                            <upload-img-list @upData= "upData" @removeData="removeData" :imageUrlList="companyForm.companyAmbientImg"></upload-img-list>
                        </el-form-item>
                        <el-form-item label="企业简介:">
                            <el-input v-model="companyForm.companyInfo" type="textarea" :rows="4" placeholder="请输入企业简介描述"></el-input>
                        </el-form-item>
                        <el-form-item label="企业视频:">
                            <upload-video :videoUrl="companyForm.companyPropaganda" @videoSuccess="videoSuccess" @changVideoStatus="changVideoStatus" :videoStatus="videoStatus"></upload-video>
                        </el-form-item>
                    </el-form>
                    <el-button class="button active" @click="addMemberInfo" :loading="loading">保 存</el-button>
                </el-col>
            </el-row>
            <video-tips v-if="videoStatus" :statusFlag="statusFlag"></video-tips>
        </el-card>
    </div>
</template>
<script>
import UploadImgList from '../../../components/uploadImgList/UploadImgList'
import UploadVideo from '../../../components/uploadVideo/Index'
import VideoTips from '../components/VideoTips'
import companyRequest from '../../../api/company'
// 验证手机号的规则
let checkMobile = (rule, value, cb) => {
    const regMobile = /^1[0-9]{10}$/

    if (regMobile.test(value)) {
    return cb()
    }
    cb(new Error('请输入合法的手机号'))
}
// 验证资金
let checkNumber = (rule, value, cb) => {
    const regNumber = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/

    if (regNumber.test(value)) {
    return cb()
    }
    cb(new Error('请输入合法注册资金'))
}
export default {
    components: {
        UploadImgList,
        UploadVideo,
        VideoTips
    },
    mounted() {
        this.getBaseInfo()
        this.getIndustry()
        this.getNature()
        this.getCityList('provinceList', 1)
    },
    data() {
        return {
            loading:false,
            provinceList: [], //省列表
            cityList: [], //市列表
            areaList: [], //区域列表
            province: '', //选中省
            city: '', //选中市
            area: '', //区域
            industryList: [], //公司行业分类
            natureList: [], //公司性质分类
            companyScaleist: [ //企业规模
                {name: '10人以下'},
                {name: '10-50人'},
                {name: '50-200人'},
                {name: '200-500人'},
                {name: '500-1000人'},
                {name: '1000人以上'}
            ], 
            pics: [],
            videoStatus: false,
            statusFlag: 1, //1视频上传中、2视频上传成功、3视频上传失败
            companyForm: {
                companyName: '', //公司名字
                id: null,
                register: null, //注册资金
                companyIndustryId: '', //公司行业
                companyNatureId: '', //公司性质
                companyArea: [], //区域
                companyScale: '', //公司规模
                companyAddress: '', //公司详细地址
                companyInfo: '', //公司简介
                companyContactsName: '', //公司联系人
                companyContactsPhone: '', //公司联系人电话
                companyFixedTelephone: '', //公司固定电话
                companyEmail: '', //公司邮箱
                companyPropaganda: '', //公司宣传片
                companyPropagandafm: '', //公司宣传片
                companyAmbientImg: [] //企业环境
            },
            companyFormRules: { //验证规则
                companyName: [{required: true, message: '请填写公司名称', trigger: 'blur'}],
                companyAddress: [{required: true, message: '请填写公司详细地址(如街道、小区、楼栋号等)', trigger: 'blur'}],
                register: [{required: true, message: '请输入公司注册资金', trigger: 'blur'}, { validator: checkNumber, trigger: 'blur' }],
                companyIndustryId: [{required: true, message: '请选择公司所属行业', trigger: 'blur'}],
                companyNatureId: [{required: true, message: '请选择公司所属性质', trigger: 'change'}],
                companyScale: [{required: true, message: '请选择公司规模', trigger: 'change'}],
                companyContactsName: [{required: true, message: '请填写联系人姓名', trigger: 'blur'}],
                companyContactsPhone: [{required: true, message: '请填写联系人电话', trigger: 'blur'}, { validator: checkMobile, trigger: 'blur' }]
            }
        }
    },
    methods: {
        addMemberInfo() {
            this.$refs.companyFormRef.validate((valid) => {
                if (valid) {
                    if (!this.province) {
                        this.$message.error('省份不能为空')
                        return
                    }
                    if (!this.city) {
                        this.$message.error('城市不能为空')
                        return
                    }
                    if (this.areaList.length > 0) {
                        if (!this.area) {
                            this.$message.error('区域不能为空')
                            return
                        }
                    }
                    this.loading = true
                    // console.log('companyForm', this.companyForm)
                    this.pics = []
                    const obj = {
                        id: this.companyForm.id,
                        companyName: this.companyForm.companyName, //公司名字
                        companyIndustryId: this.companyForm.companyIndustryId, //公司行业
                        companyNatureId: this.companyForm.companyNatureId, //公司性质
                        register: this.companyForm.register, //公司注册资金
                        companyScale: this.companyForm.companyScale, //公司规模
                        companyAddress: this.companyForm.companyAddress, //公司详细地址
                        companyInfo: this.companyForm.companyInfo, //公司简介
                        companyContactsName: this.companyForm.companyContactsName, //公司联系人
                        companyContactsPhone: this.companyForm.companyContactsPhone, //公司联系人电话
                        companyFixedTelephone: this.companyForm.companyFixedTelephone, //公司固定电话
                        companyEmail: this.companyForm.companyEmail, //公司邮箱
                        companyPropaganda: this.companyForm.companyPropaganda, //企业宣传片
                        coverPicture: this.companyForm.companyPropagandafm, //企业宣传片
                        companyAmbientImg: []
                    }
                    obj.companyArea = `${this.province},${this.city},${this.area}`
                    this.companyForm.companyAmbientImg.forEach(item => {
                        if (item.response && item.response.code === 200) {
                            this.pics.push(item.response.data.message)
                        } else {
                            this.pics.push(item.url)  
                        }
                    })
                    // this.companyForm.companyAmbientImg.forEach((item, index) => {
                    //     obj.companyAmbientImg.push(item.url)
                    // }) 
                    obj.companyAmbientImg = this.pics
                    companyRequest.addMemberInfo(obj).then((res) => {
                        if (res.code === 200) {
                            this.loading = false
                            // this.industryList = res.data
                            this.$message.success('保存成功~')
                            window.localStorage.setItem('memberBaseInfoId', 1)
                            // this.$store.push({
                            //     path: '/company/mine'
                            // })
                            // this.getBaseInfo()
                            this.$router.push({
                                path: '/company/mine'
                            })
                            // setTimeout(() => {
                            //     this.$router.push({
                            //         path: '/company/mine'
                            //     })
                            // }, 400)
                        }
                    }).catch((err) => {
                        this.loading = false
                        this.$message.error(err)
                    })
                
                } else {
                    this.$alert('带 * 号内容为必填项，请先补全信息再提交~', '信息缺失', {
                        confirmButtonText: '确定',
                        callback: action => {}
                    })
                    return false
                }
            })
            // console.log('companyForm', this.companyForm)
            // this.pics = []
            // const obj = {
            //     companyName: this.companyForm.companyName, //公司名字
            //     companyIndustryId: this.companyForm.companyIndustryId, //公司行业
            //     companyNatureId: this.companyForm.companyNatureId, //公司性质
            //     companyScale: this.companyForm.companyScale, //公司规模
            //     companyAddress: this.companyForm.companyAddress, //公司详细地址
            //     companyInfo: this.companyForm.companyInfo, //公司简介
            //     companyContactsName: this.companyForm.companyContactsName, //公司联系人
            //     companyContactsPhone: this.companyForm.companyContactsPhone, //公司联系人电话
            //     companyFixedTelephone: this.companyForm.companyFixedTelephone, //公司固定电话
            //     companyEmail: this.companyForm.companyEmail, //公司邮箱
            //     companyPropaganda: this.companyForm.companyPropaganda, //企业宣传片
            //     companyAmbientImg: []
            // }
            // this.companyForm.companyAmbientImg.forEach(item => {
            //     if (item.response && item.response.code === 200) {
            //         this.pics.push(item.response.data.message)
            //     } else {
            //         this.pics.push(item.url)  
            //     }
            // })
            // // this.companyForm.companyAmbientImg.forEach((item, index) => {
            // //     obj.companyAmbientImg.push(item.url)
            // // }) 
            // obj.companyAmbientImg = this.pics
            // companyRequest.addMemberInfo(obj).then((res) => {
            //     if (res.code === 200) {
            //         // this.industryList = res.data
            //         this.$message.success('保存成功~')
            //     }
            // }).catch((err) => {
            //     this.$message.error(err)
            // })
        },
        //城市联动
        getCityList(location, id) {
            companyRequest.getCityList({id: id}).then((res) => {
                if (res.code === 200) {
                    this[location] = res.data
                    // console.log('location', location)
                }
            })
        },
        //省份发生变化
        handleProvinceChange(val) {
            // console.log('val', val)
            this.getCityList('cityList', val)
            this.city = ''
            this.areaList = []
            this.area = ''
        },
        //城市发生变化
        handlecityChange(val) {
            // console.log('val', val)
            this.getCityList('areaList', val)
            this.area = ''
        },
        //区域发生变化
        handleAreaChange(val) {
            // console.log('val', val)
        },
        //修改上传提示组件状态
        changVideoStatus(states) {
            if (states === 'upload') {
                this.videoStatus = true
                this.statusFlag = 1
            } else if (states === 'success') {
                this.statusFlag = 2
                setTimeout(() => {
                    this.videoStatus = false
                }, 1500)
            } else if (states === 'error') {
                this.statusFlag = 3
                setTimeout(() => {
                    this.videoStatus = false
                }, 1500)
            }
        },
        //获取公司环境照片地址(添加上传)
        upData(val) {
            // console.log('val', val)
            // this.companyForm.companyAmbientImg .push({url: val, uid: Math.random()})
            this.pics = []
            this.companyForm.companyAmbientImg = val
            // console.log('companyAmbientImg', this.companyForm.companyAmbientImg)
            // this.companyForm.companyAmbientImg.forEach(item => {
            //     if (item.response && item.response.code === 200) {
            //         this.pics.push(item.response.data.message)
            //     } else {
            //         this.pics.push(item.url)  
            //     }
            // })
            // console.log('添加pics', this.pics)
        },
        //获取公司环境照片地址(移除)
        removeData(url) {
        //    this.companyForm.companyAmbientImg = this.companyForm.companyAmbientImg.filter(item => {
        //        return item.url != url.url
        //    }) 
            this.pics = []
            this.companyForm.companyAmbientImg = url
            this.companyForm.companyAmbientImg.forEach(item => {
                if (item.response && item.response.code === 200) {
                    this.pics.push(item.response.data.message)
                } else {
                    this.pics.push(item.url)  
                }
            })
            // console.log('移除pics', this.pics)
            // console.log('remove', this.companyForm.companyAmbientImg)
        },
        //获取视频地址
        videoSuccess(val) {
            this.companyForm.companyPropaganda = val[0]
            this.companyForm.companyPropagandafm = val[1]
            // console.log('companyPropaganda', this.companyForm.companyPropaganda)
        },
        //获取公司行业分类
        getIndustry() {
            companyRequest.getIndustry().then((res) => {
                if (res.code === 200) {
                    this.industryList = res.data
                }
            })
        },
        //获取公司性质分类
        getNature() {
            companyRequest.getNature().then((res) => {
                if (res.code === 200) {
                    this.natureList = res.data
                }
            })
        },
        //获取企业基本信息
        getBaseInfo() {
            this.pics = []
            this.companyForm.companyAmbientImg = []
            companyRequest.getBaseInfo().then((res) => {
                if (res.code === 200) {
                    this.companyForm.id = res.data.id || null
                    this.companyForm.companyAddress = res.data.companyAddress || ''
                    this.companyForm.companyContactsName = res.data.companyContactsName
                    this.companyForm.companyContactsPhone = res.data.companyContactsPhone
                    this.companyForm.register = res.data.register
                    this.companyForm.companyEmail = res.data.companyEmail
                    this.companyForm.companyFixedTelephone = res.data.companyFixedTelephone
                    this.companyForm.companyIndustryId = res.data.companyIndustryId
                    this.companyForm.companyInfo = res.data.companyInfo
                    this.companyForm.companyName = res.data.companyName
                    this.companyForm.companyNatureId = res.data.companyNatureId
                    this.companyForm.companyPropaganda = res.data.companyPropaganda
                    this.companyForm.companyScale = res.data.companyScale
                    this.companyForm.companyPropaganda = res.data.companyPropaganda
                    this.companyForm.companyPropaganda = res.data.companyPropaganda
                    this.companyForm.companyPropagandafm = res.data.coverPicture
                }
                res.data.companyAmbientImg = res.data.companyAmbientImg ? res.data.companyAmbientImg.split(',') : []
                res.data.companyAmbientImg.forEach((item, index) => {
                    this.companyForm.companyAmbientImg.push({url: item})
                })
                this.pics = this.companyForm.companyAmbientImg
                //城市回填
                this.companyForm.companyArea = res.data.companyArea.split(',')
                this.companyForm.companyArea = this.companyForm.companyArea.map((item) => {
                    return item = parseInt(item)
                })
                this.province = this.companyForm.companyArea[0]
                this.city = this.companyForm.companyArea[1]
                this.area = this.companyForm.companyArea[2]
                //拉取城市相关数据
                this.getCityList('cityList', this.province)
                this.getCityList('areaList', this.city)

            })
        }

    },
    beforeRouteLeave(to, from, next) {
         if (window.localStorage.getItem('memberBaseInfoId') == 0) {
             if (to.path == '/newLogin') {
                 next()
             } else if (window.localStorage.getItem('authInfoStatus') == 0 || window.localStorage.getItem('authInfoStatus') == 2) {
                 if (to.path == '/company/certification') {
                     next()
                 }
             } else {
                 this.$alert('基本资料尚未完善，请先填写基本信息~', {
                    confirmButtonText: '确定',
                    callback: action => { 
                        // this.$message.warning('请先认证企业信息后,在进入其他页面~')
                        this.$store.commit('changeActivePath', {storeActivePath: '/company/companyInfo'})
                    }
                })
                return
             }
            // this.$alert('基本资料尚未完善，请先填写基本信息~', {
            //     confirmButtonText: '确定',
            //     callback: action => { 
            //         // this.$message.warning('请先认证企业信息后,在进入其他页面~')
            //     }
            // })
            // return
        }
        next()
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
.company-info{
    height: 100%;
    position: relative;
    .el-card{
        padding-bottom: 20px;
    }
    .button{
        margin-top: 10px;
        width: 200px;
    }
    .el-select{
        width: 100%;
    }
}
</style>